import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginPage from '../views/Login.vue'
import PreguntesPage from '../views/Preguntes.vue'
import UsuarisPage from '../views/Users.vue'
import FaqsPage from '../views/Faqs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/preguntes',
    name: 'Preguntes',
    component: PreguntesPage
  },
  {
    path: '/usuaris',
    name: 'Usuaris',
    component: UsuarisPage
  },
  {
    path: '/faqs',
    name: 'FAQS',
    component: FaqsPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) =>{

  const publicPages = ['/login','/']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  console.log("Router redirect",to,from)
  if(authRequired && !loggedIn){
    return next('/login')
  }
  next()
})

export default router
