<template>
  <div class="home">
    <b-jumbotron header="Enquesta GdT" lead="Implementada per lequia">
      <b-button to="/login">Començar</b-button>
    </b-jumbotron>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
