<template>
  <b-container class="loginPage">
    <b-row>
      <b-col>
        <h1>Pagina d'identificació</h1>
        <b-form-input v-model="email"></b-form-input>
        <b-form-input v-model="password"></b-form-input>
        <b-button @click="makeLogin">Login</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "Login",
  data(){
    return{
      email: "",
      password: ""
    }
  },
  methods:{
    makeLogin(){
      console.log("sending Log in")
      const { dispatch } = this.$store;
      if (this.email !== '' && this.password !== '') {
        dispatch('authentication/login', { username: this.email, password: this.password });
      }
    }
  }
}

</script>

<style scoped>

</style>