<template>
  <b-container>
    <b-row >
      <b-col>
        <h1>Usuaris</h1>
        <b-button  class="float-right" @click="addUser()">Afegir usuari</b-button>
        <b-button  variant="primary" @click="exportUser()" class="mr-1 float-right">
          <b-spinner v-if="exporting"></b-spinner>
          Exportar
        </b-button>
      </b-col>
    </b-row>
    <b-table class="mt-4" :fields="fields" :items="listUsers" :tbody-tr-class="rowClass">
      <template #cell(id)="data">
        <span> {{ data.item.id }}</span>
      </template>
      <template #cell(admin_name)="data">
        <b-form-input type="text" v-model="data.item.admin_name"></b-form-input>
      </template>
      <template #cell(email)="data">
        <b-form-input type="text" v-model="data.item.email"></b-form-input>
      </template>
      <template #cell(pass)="data">
        <b-form-input type="text" v-model="data.item.pass"></b-form-input>
      </template>
      <template #cell(role)="data">
        <span v-if="data.item.role === 1"> Administrador</span>
        <span v-else> Usuari</span>
      </template>
      <template #cell(answers)="data">
        <span>{{listAnswerUser[data.item.id]}}/118</span>
      </template>
      <template #cell(finalitzada)="data">
        <b-checkbox value="1" unchecked-value="0" v-model="data.item.finalitzada"> </b-checkbox>
      </template>
      <template #cell(controls)="data" class="pr-0">
        <div class="float-right controlsCell">
          <b-button size="sm" variant="info" @click="modifyUser(data.item)">
            <b-spinner v-if="data.item.sending" small></b-spinner>
            Guardar
          </b-button>
          <b-button size="sm" variant="danger" @click="deleteUser(data.item,data.index)" class="ml-1">
            <b-spinner v-if="data.item.deleting" small></b-spinner>
            Eliminar
          </b-button>

        </div>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import Axios from "axios";
import {authHeader} from "@/helpers";
import XLSX from "xlsx";

export default {
  name: "Users",
  data(){
    return{
      exporting: false,
      listUsers: [],
      listAnswerUser: {},
      allAnswers: [],
      allQuestions: [],
      fields: [
        {key: 'id', label: 'ID', sortable: true},
        {key: 'admin_name', label: 'Administració', sortable: true},
        {key: 'email', label: 'Nom d\'usuari', sortable: false},
        {key: 'pass', label: 'Password', sortable: false},
        {key: 'role', label: 'Role', sortable: false},
        {key: 'answers', label: '', sortable: false},
        {key: 'finalitzada', label: 'Finalitzada', sortable: false},
        {key: 'controls', label: '', sortable: false},
      ]
    }
  },
  methods:{
    addUser(){
      this.listUsers.push({"id":-1,"admin_name":"Nom administració","role":2,"email":"Nom Usuari","pass":"test","last_login":"0000-00-00"})
    },
    exportUser(){

      let _this = this
      let generatedJSON = []

      this.allQuestions.forEach(questions => {

        let onlyFirst = false
        if(questions[0].type === '-') onlyFirst = true
        let isFirstRow = true

        questions.forEach(question => {

            let rowData = {}
            rowData["Id"] = question.code_prim
            rowData["Pregunta"] = question.description

            _this.listUsers.forEach(user => {

              let user_answers = _this.allAnswers.find(answer => {
                return user.id === answer.user_id
              })
              if (user_answers) {
                // if (question[0].code_prim === "1_1") {
                //   console.log(user_answers, question[0].code_prim, user_answers.json_data[question[0].code_prim])
                // }

                if (user_answers.json_data[question.code_prim]) {
                  rowData[user.admin_name] = user_answers.json_data[question.code_prim]
                } else {
                  rowData[user.admin_name] = ""
                }
              } else {
                rowData[user.admin_name] = ""
              }
            })
          if(onlyFirst && isFirstRow || !onlyFirst){
            isFirstRow = false
            generatedJSON.push(rowData)
          }
        })
      })
      console.log(this.allAnswers)
      console.log(generatedJSON)
      const ws = XLSX.utils.json_to_sheet(generatedJSON)

      const wb = XLSX.WorkBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'enquesta')
      XLSX.writeFile(wb, 'enquesta_data.xlsx')

    },
    modifyUser(user){
      let _this = this
      if(user.sending) return
      this.$set(user,'sending',true);
      if(user.id === -1){
        Axios.post('/users/addUser',{user},{
          headers: {
            Authorization: authHeader().Authorization
          }
        }).then(res => {
          if(res.data.error) {}
          else{
            user.id = res.data.result.insertId
            user.sending = false;
          }
        })
      }
      else{
        Axios.post('/users/modifyUser',{user},{
          headers: {
            Authorization: authHeader().Authorization
          }
        }).then(res => {
          if(res.data.error) {
            console.log(res.data.err)
            user.sending = false;
          }
          else{
            console.log(res)
            user.sending = false;
          }
        })
      }
    },
    deleteUser(user,index){

      let r = confirm("Segur que vols eliminar l'usuari? (Aquesta acció no es pot desfer)");
      if (r !== true) return
      console.log("Usuari eliminat")
      if(user.id === -1){
        if(index > -1)this.listUsers.splice(index, 1)
      }
      else{
        if(user.deleting) return
        this.$set(user,'deleting',true);
        Axios.post('/users/deleteUser',{user},{
          headers: {
            Authorization: authHeader().Authorization
          }
        }).then(res => {
          if(res.data.error) {
            console.log(res.data.err)
            user.deleting = false;
          }
          else{
            console.log(res)
            user.deleting = false;
            if(index > -1)this.listUsers.splice(index, 1)
          }
        })
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.id === -1) return 'table-secondary'
    }
  },
  mounted() {
    let _this = this
    Axios.get('/questions/',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      for(const prop in res.data){
        _this.allQuestions.push(res.data[prop])
      }
      //this.questions = JSON.parse(res.data).entries()
    }).catch(err => console.error(err))
    Axios.get('/users',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      //console.log(res)
      _this.listUsers = res.data
    })
    Axios.get('/answers/answerUser',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      console.log(res)
      let auxArray = []
      auxArray = res.data.rows
      _this.allAnswers = res.data.rows
      auxArray.forEach(row => {
        let json = JSON.parse(row.json_data);
        row.json_data = json
        let keys = Object.keys(json)
        let numberOfAnswers = 0
        keys.forEach(key => {
          if(key.endsWith("_1")){
            if (json[key].trim() !== "") numberOfAnswers++
          }
        })
        _this.listAnswerUser[row.user_id] = numberOfAnswers
      })
    })
  }

}
</script>

<style scoped>
  .controlsCell{
    min-width: 140px;
  }
</style>