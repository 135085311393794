<template>
  <b-container class="questions">
    <b-row>
      <b-col>
        <h1>Enquesta - {{ user.admin_name }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert v-model="showErrorSending" variant="danger" dismissible>
          Alguna cosa ha fallat el {{ alertDate }}, torna a intentar-ho.
        </b-alert>
        <b-alert v-model="showSuccessSending" variant="success" dismissible>
          Dades enviades correctament, el {{ alertDate }}
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-for="(question, ques_index) in questions" v-bind:key="ques_index">
      <b-col cols="12" v-if="question.length === 1">
        <b-row v-for="(subquestion, sub_index) in question" v-bind:key="sub_index">
          <b-col v-if="['km²','uts','m³/any','kWh/any','Tn/any','%','uts/any','anys','cm','km','€/Any','€','hores','dies','heq'].includes(subquestion.type)">
            <b-form-group :disabled="finalitzada" :label="(ques_index+1)+'.'+(sub_index+1)+' - '+subquestion.question_text" >
              <b-input-group :append="subquestion.type">
                <b-form-input type="number" step="any" v-model="answers[subquestion.code_prim]"> </b-form-input>
              </b-input-group>
              <template #description >
                <span v-html="subquestion.description"> </span>
              </template>
            </b-form-group>
          </b-col>
          <b-col v-else-if="subquestion.type === 'Sí/No'">
            <b-form-group :disabled="finalitzada" :label="(ques_index+1)+'.'+(sub_index+1)+' - '+subquestion.question_text" :description="subquestion.description">
              <b-form-radio-group v-model="answers[subquestion.code_prim]">
                <b-form-radio value="Sí">Sí</b-form-radio>
                <b-form-radio value="No">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

          </b-col>
          <b-col v-else>
            {{subquestion.type}}-{{subquestion.question_text }}
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else-if="question[0].type === 'Sí/No'">
        <b-row v-for="(subquestion, sub_index) in question" v-bind:key="sub_index">
          <b-col>
            <b-form-group :disabled="finalitzada" v-if="sub_index === 0" :label="(ques_index+1)+'.'+(sub_index+1)+' - '+subquestion.question_text" :description="subquestion.description">
              <b-form-radio-group v-model="answers[subquestion.code_prim]">
                <b-form-radio value="Sí">Sí</b-form-radio>
                <b-form-radio value="No">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group :disabled="finalitzada" class="ml-5" v-else-if="answers[question[0].code_prim] && answers[question[0].code_prim] === 'Sí'" :label="(ques_index+1)+'.'+(sub_index+1)+' - '+subquestion.question_text" :description="subquestion.description">
              <b-form-radio-group v-if="subquestion.type === 'Sí/No'" v-model="answers[subquestion.code_prim]" >
                <b-form-radio value="Sí">Sí</b-form-radio>
                <b-form-radio value="No">No</b-form-radio>
              </b-form-radio-group>
              <b-form-radio-group :disabled="finalitzada" v-else-if="subquestion.type === '%'" v-model="answers[subquestion.code_prim]" >
                <b-input-group :append="subquestion.type">
                  <b-form-input type="number" v-model="answers[subquestion.code_prim]"> </b-form-input>
                </b-input-group>
              </b-form-radio-group>
              <b-form-radio-group :disabled="finalitzada" v-else-if="['km²','uts','m³/any','kWh/any','Tn/any','%','uts/any','anys','cm','km','€/Any','€','hores','dies','heq'].includes(subquestion.type)" v-model="answers[subquestion.code_prim]" >
                <b-input-group :append="subquestion.type">
                  <b-form-input type="number" v-model="answers[subquestion.code_prim]"> </b-form-input>
                </b-input-group>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else-if="question[0].type === '-'">
        <b-row>
          <b-col>
            <b-form-group :disabled="finalitzada" :label="(ques_index+1)+'.'+1+' - '+question[0].question_text" :description="question[0].description">
              <b-form-radio-group  v-model="answers[question[0].code_prim]">
                <b-form-radio v-if="sub_index > 0" v-for="(subquestion, sub_index) in question" v-bind:key="sub_index" :value="subquestion.question_text">{{ subquestion.question_text }}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
<!--      <b-col cols="12" v-else>-->
<!--        <b-row v-for="(subquestion, sub_index) in question" >-->
<!--          {{question.length}} {{ subquestion.question_text }}-->
<!--        </b-row>-->
<!--      </b-col>-->
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-button v-if="!finalitzada" variant="danger" @click="enviarTancament()">Tancament Definitiu</b-button>
      </b-col>
    </b-row>
    <b-button v-if="!finalitzada" class="fixed-bottom" @click="enviarCanvis()" style="left: 15px; bottom: 15px">
      <b-spinner v-if="sending" small></b-spinner>
      Guardar
    </b-button>
  </b-container>
</template>

<script>
import Axios from "axios";
import { authHeader} from "@/helpers";

export default {
  name: "Preguntes",
  data(){
    return{
      questions: [],
      answers:{},
      sending: false,
      showErrorSending: false,
      showSuccessSending: false,
      alertDate: "",

    }
  },
  mounted() {
    let _this = this
    Axios.get('/questions/',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      for(const prop in res.data){
        _this.questions.push(res.data[prop])
      }
      //this.questions = JSON.parse(res.data).entries()
    }).catch(err => console.error(err))
    Axios.get('/answers/',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      if(res.data.json_data){
        _this.answers = JSON.parse(res.data.json_data)
      }

      //this.questions = JSON.parse(res.data).entries()
    }).catch(err => console.error(err))
  },
  computed:{
    user(){ return this.$store.getters["authentication/user"] },
    finalitzada(){ return 1 === this.$store.getters["authentication/user"].finalitzada}
  },
  methods:{
    getDate(){
      let f = new Date();
      return f.getDate() + "/"+ f.getMonth()+ "/" +f.getFullYear() +" a les "+f.getHours()+":"+f.getMinutes() ;
    },
    enviarTancament(){
      let _this = this
      if (confirm('Estas segur que vols tancar l\'enquesta? (No es podràn tornar a modificar les respostes)')) {
        // Save it!
        console.log('Sí');
        this.enviarCanvis();
        Axios.post('/users/finalitzarUser',{
          finalitzada: 1
        }, {
          headers: {
            Authorization: authHeader().Authorization
          }
        }).then(res => {

          if(res.data.error){
            console.log("error:", res.data.err)
          }
          else{
            this.$store.dispatch('authentication/finalitzada');
          }
          //this.questions = JSON.parse(res.data).entries()
        }).catch(err => {
          console.error(err)
        })
      } else {
        // Do nothing!
        console.log('No');
      }
    },
    enviarCanvis(){
      let _this = this
      if(_this.sending) return;
      _this.sending = true;
      Axios.post('/answers/',{
        answers: _this.answers
      }, {
        headers: {
          Authorization: authHeader().Authorization
        }
      }).then(res => {
        _this.sending = false
        if(res.data.error){
          _this.showErrorSending = true
          _this.alertDate = _this.getDate()
        }
        else{
          _this.showSuccessSending = true
          _this.alertDate = _this.getDate()
        }
        //this.questions = JSON.parse(res.data).entries()
      }).catch(err => {
        _this.sending = false;
        _this.showErrorSending = true
        _this.alertDate = _this.getDate()
        console.error(err)
      })
    }
  }
}
</script>
