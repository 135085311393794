<template>
  <div id="app">
    <b-navbar variant="light ">
      <b-navbar-brand to="/">Enquesta</b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item to="/preguntes">Preguntes</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item to="/faqs">F.A.Q.S.</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="user && user.role === 1">
        <b-nav-item to="/usuaris">Usuaris</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="!isLoggedIn" class="ml-auto">
        <b-nav-item to="/login">Entrar</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-else class="ml-auto">
        <b-nav-item @click="logOut" to="/">Sortir</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>

import { userService} from "@/services";

export default{
  name: 'APP',
  data(){
    return{

    }
  },
  methods:{
    logOut(){
      this.$store.dispatch('authentication/logout');
    }
  },
  computed:{
    isLoggedIn(){ return this.$store.getters["authentication/isLoggedIn"]},
    user(){return this.$store.getters["authentication/user"]}
  },
  mounted() {
    // if(localStorage.getItem('user')) this.isLoggedIn = true
    // else this.isLoggedIn = false
  }

}
</script>


