import router from "@/router";
import { userService } from "@/services";

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication =  {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit('loginRequest', { username });
      userService.login(username, password)
        .then(
          user => {
            console.log(user)
            if(user.accessToken){
              commit('loginSuccess', user);
              router.push('/preguntes');
            }
          },
          error => {
            commit('loginFailure', error);
            //dispatch('alert/error', error, { root: true });
          }
        );
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    },
    finalitzada({commit}){
     commit('userFinalitzada')
    }
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    userFinalitzada(state){
      state.user.finalitzada = 1;
    }
  },
  getters:{
    isLoggedIn: state => !!state.user,
    user: state => state.user,
    authStatus: state => state.status,
  }

}