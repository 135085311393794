import {authHeader} from "@/helpers";
import Axios from "axios";

export const userService = {
  login,
  logout
}

function login(username, password) {
  return new Promise((resolve, reject) =>{
    Axios.post('/users/login', {
      username: username,
      password: password
    }).then(user => {
      if(user.data.accessToken){
        localStorage.setItem('user',JSON.stringify(user.data))
      }
      resolve(user.data);
    }).catch(err => {reject(err)})
  })

}

function logout(){
  localStorage.removeItem('user')
}

