<template>
  <b-container>
    <b-row >
      <b-col>
        <h1>FAQS</h1>
        <b-button class="float-right" v-b-toggle.collapse-1 >Afegir pregunta</b-button>

      </b-col>
    </b-row>
    <b-row class="addQuestion mb-4" >
      <b-col>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <h4 class="card-text">Nova pregunta:</h4>
            <b-input v-model="pregunta_title" placeholder="Resum de la pregunta..." class="mb-2"></b-input>
            <b-form-textarea
                class="mb-2"
                id="textarea"
                v-model="pregunta_text"
                placeholder="Defineix la pregunta en detall..."
                rows="3"
                max-rows="6"
            ></b-form-textarea>
            <b-button variant="danger" class="mr-2">Eliminar</b-button>
            <b-button variant="primary" @click="addFaq">Guardar</b-button>

          </b-card>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-for="faq in listFaqs" :key="faq.id">
      <b-col cols="12" v-if="user.role === 2">
        <h3>{{ faq.title }}</h3>
        <pre>{{ faq.question }}</pre>
        <pre><h5>Resposta:</h5>{{ faq.answer }}</pre>
      </b-col>
      <b-col cols="12" v-else-if="user.role === 1">

        <h3>{{ faq.title }}</h3>
        <pre>{{ faq.question }}</pre>
        <pre><h5>Resposta:</h5>{{ faq.answer }}</pre>
        <b-form-group label="Titol:" >
          <b-input-group>
            <b-form-input v-model="faq.title"> </b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Detall:" >
          <b-input-group>
            <b-form-textarea v-model="faq.question"> </b-form-textarea>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Resposta:" >
          <b-input-group>
            <b-form-textarea v-model="faq.answer"> </b-form-textarea>
          </b-input-group>
        </b-form-group>
        <b-button variant="danger" @click="deleteFaq(faq)" class="mr-2">Eliminar</b-button>
        <b-button variant="primary" @click="modifyFaq(faq)">Guardar</b-button>
      </b-col>
      <b-col cols="12" class="mt-3 border-bottom">

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Axios from "axios";
import {authHeader} from "@/helpers";

export default {
  name: "Faqs",
  data(){
    return{
      listFaqs: [],
      pregunta_title: "",
      pregunta_text: ""
    }
  },
  computed:{
    isLoggedIn(){ return this.$store.getters["authentication/isLoggedIn"]},
    user(){return this.$store.getters["authentication/user"]}
  },
  methods:{
    addFaq(){
      let _this = this;

      if(_this.pregunta_title.trim() === "") return

      Axios.post('/faqs/addFaq',{
        title: _this.pregunta_title,
        pregunta: _this.pregunta_text
      },{
        headers: {
          Authorization: authHeader().Authorization
        }
      }).then(res => {
        if(res.data.error) {console.log(res.data.err)}
        else{

          _this.pregunta_title = ""
          _this.pregunta_text = ""
          _this.$router.go()
        }
      })
    },
    modifyFaq(faq){
      let _this = this

      Axios.post('/faqs/modifyFaq',{faq},{
        headers: {
          Authorization: authHeader().Authorization
        }
      }).then(res => {
        if(res.data.error) {}
        else{
          _this.pregunta_title = ""
          _this.pregunta_text = ""

          _this.$router.go()
        }
      })

    },
    deleteFaq(faq){

      let _this = this
      Axios.post('/faqs/deleteFaq',{faq},{
        headers: {
          Authorization: authHeader().Authorization
        }
      }).then(res => {
        if(res.data.error) {
          console.log(res.data.err)
        }
        else{
          console.log(res)
          _this.$router.go()
        }
      })
    }

  },
  mounted() {
    let _this = this
    Axios.get('/faqs',{
      headers: {
        Authorization: authHeader().Authorization
      }
    }).then(res => {
      console.log(res)
      _this.listFaqs = res.data
    })
  }


}
</script>

<style scoped>
  .controlsCell{
    min-width: 215px;
  }
</style>